/* eslint-disable sort-keys */

export const theme = {
  color: {
    blue: '#4B89F6',
    dark: '#162234',
    darkGray: '#4f5b6d',
    darkGreen: '#389d91',
    dashDarkRed: '#FEAFB5',
    errorRed: '#f44336',
    gray: '#939aa6',
    green: '#44baac',
    lightBlue: '#cfe2e8',
    lightGray: '#d8dce3',
    lightGreen: '#1adbb6',
    lightRed: '#f4e4e4',
    midGray: '#585d61',
    midRed: '#f4cccd',
    orange: '#dd7a02',
    paleGreen: '#d9ead3',
    paleGreenDash: '#B7D6AA',
    paleLightGreen: '#d5f9f5',
    paleRed: '#ff757c',
    palerGreenDash: '#D9EAD4',
    paleYellow: '#FFE699',
    red: '#d24d57',
    tint: '#f7f9fa',
    white: '#fff',
    yellow: '#EEC900',
  },
}

export const colors = {
  green: '#2ECAB1',
  lightGray: '#e4e4e4',
  default: '#6c8598',
  active: theme.color.green,
  incomplete: theme.color.lightGray,
  paused: theme.color.yellow,
  cancelled: theme.color.red,
  error: theme.color.errorRed,
}
