import { useState } from 'react'
import { RowTable, Header } from '~/app/components/Table'
import Loader from '~/app/components/Loader'
import OrderRow from './Order'
import OrderDetailsLightBox from './OrderDetails'
import { SpacerCol } from './styled'
import withFaultyOrderController from './withFaultyOrderController'
import { Order } from './types'
import type { Props as WithFaultyOrderProps } from './withFaultyOrderController'
import { getOrderProductCount, OrderProductCountReturnType } from './helpers'
import type { Plan } from '~/app/store/plans/types'

interface Props extends WithFaultyOrderProps {
  userId: number
  orders: Order[] | null
  plans: Plan[] | null
}

function UserOrders(props: Props) {
  const self = useController(props)

  if (!props.orders || !props.plans) {
    return <Loader />
  }

  return (
    <RowTable>
      <thead>
        <tr>
          <Header>Order ID</Header>
          <Header>SHIPMENT ID</Header>
          <Header>Date</Header>
          <Header>Status</Header>
          <Header>Coupon</Header>
          <Header>Items</Header>
          <Header>Total</Header>
          <Header>Actions</Header>
          <SpacerCol />
        </tr>
      </thead>
      <tbody>
        {props.orders?.map((order, i) => (
          <OrderRow
            key={i} // different orders can have the same id if they are not altered
            index={i}
            onClick={self.setOrderDetailsOrderId}
            onSetAsFaulty={props.setFaultyOrder}
            order={order}
            productCount={self.orderIdToProductCountMap.get(order.id)!}
          />
        ))}
      </tbody>
      {self.orderDetailsOrderId !== null && (
        <OrderDetailsLightBox
          closeHandler={self.closeLightBox}
          order={self.orderIdToOrderMap.get(self.orderDetailsOrderId)!}
          productCount={self.orderIdToProductCountMap.get(self.orderDetailsOrderId)!}
        />
      )}
    </RowTable>
  )
}

function useController(props: Props) {
  const [orderDetailsOrderId, setOrderDetailsOrderId] = useState<number | null>(null)
  const orderIdToOrderMap = new Map<number, Order>(props.orders?.map((order) => [order.id, order]))
  const planIdToPlanMap = new Map<number, Plan>(props.plans?.map((plan) => [plan.id, plan]))
  const orderIdToProductCountMap = new Map<number, OrderProductCountReturnType>(
    props.orders?.map((order) => [order.id, getOrderProductCount(planIdToPlanMap, order)]),
  )
  const closeLightBox = () => setOrderDetailsOrderId(null)

  return {
    closeLightBox,
    orderDetailsOrderId,
    orderIdToOrderMap,
    orderIdToProductCountMap,
    setOrderDetailsOrderId,
  }
}

export default withFaultyOrderController(UserOrders)
