import Plan from '~/app/components/Plan'
import { Item } from './styled'
import type { ProductType } from '~/app/common/types'
import { useMappedState } from '~/app/hooks/useReduxStore'
import user$ from '~/app/store/user'
import type { PastOrders } from '~/app/store/user/pastOrders/types'

interface Props {
  kid: {
    name: string
    subscriptions: Array<{
      id: number
      planId: number
      mealsPerDay: null | number
      mealsPerDelivery: null | number
      groupName: string
      type: ProductType
      active: boolean
      deletedAt: null | string
      deactivatedAt: null | string
      parentSubscription: null | number
      createdAt: string
    }>
  }
  plans: {
    id: number
    mealsPerDelivery: number
    mealsPerDay: number
    groupName: string
  }[]
  user: {
    id: number
  }
}

export default function SubscriptionsList({ kid, plans, user }: Props) {
  const findPlanById = (planId: number) => (plans || []).find((p) => p.id === planId)
  const findParentSubscriptionById = (id: number) => kid.subscriptions.find((p) => p.id === id)
  const getHasFirstOrderCreated = useGetHasFirstOrderCreated()

  return (
    <>
      {kid.subscriptions.map((subscription) => {
        const showPlanActionsButton = subscription.parentSubscription
          ? findParentSubscriptionById(subscription.parentSubscription)?.active
          : true
        return (
          <Item key={subscription.id}>
            <Plan
              kidName={kid.name}
              type={subscription.type}
              isActive={subscription.active}
              mealsPerDay={findPlanById(subscription.planId)?.mealsPerDay}
              mealsPerDelivery={findPlanById(subscription.planId)?.mealsPerDelivery}
              templateId={subscription.id}
              userId={user.id}
              // @ts-expect-error TODO: Fix error
              deactivatedAt={subscription.deactivatedAt}
              // @ts-expect-error TODO: Fix error
              parentSubscription={subscription.parentSubscription}
              // @ts-expect-error TODO: Fix error
              deletedAt={subscription.deletedAt}
              createdAt={subscription.createdAt}
              // @ts-expect-error TODO: Fix error
              showPlanActionsButton={showPlanActionsButton}
              hasFirstOrderPending={!getHasFirstOrderCreated(subscription.id)}
            />
          </Item>
        )
      })}
    </>
  )
}

/**
 * Returns a function that checks if a subscription(template) has a past order created.
 * Past orders for a given subscription are only returned when they get charged, so if
 * there are no matching past orders, it means that the subscription has not been charged
 * for the first time yet.
 */
function useGetHasFirstOrderCreated(): (templateId: number) => boolean {
  const pastOrders: PastOrders = useMappedState(user$.getPastOrders)

  const planHasFirstOrderCreated = (templateId: number) => {
    // If past orders are not loaded yet, we return true to avoid showing the
    // the label "Pending First Order" when it should not be shown.
    if (pastOrders === null) {
      return true
    }
    return pastOrders.some((order) => order.templateId === templateId)
  }

  return planHasFirstOrderCreated
}
