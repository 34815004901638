import { Link } from 'react-router-dom'
import { Block } from '~/app/components/Block'
import Page from '~/app/components/Page'
import { Title } from '~/app/components/Titles/PageTitle'
import { productTypeNamePlural } from '~/app/common/dataMaps/productTypeName'
import { type Link as LinkType, ProductType } from '~/app/common/types'

export default function ProductDefaults() {
  const self = useController()

  return (
    <Page>
      <Title>Manage Product Defaults</Title>
      <Block>
        {self.productDefaultsLinks.map(({ name, path }) => (
          <>
            <Link to={path}>{name}</Link>
            <br />
          </>
        ))}
      </Block>
    </Page>
  )
}

function useController() {
  const productDefaultsLinks: LinkType[] = [
    ProductType.plate,
    ProductType.biteable,
    ProductType.luncher,
    ProductType.smoothie,
    ProductType.snack,
    ProductType.puff,
    ProductType.cereal,
  ].map((productType) => ({
    name: productTypeNamePlural[productType],
    path: `/product-defaults/${productType}`,
  }))

  return {
    productDefaultsLinks,
  }
}
