import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { groupBy } from 'lodash'
import Page from '~/app/components/Page'
import useHasUserRole from '~/app/hooks/useHasUserRole'
import { Title } from '~/app/components/Titles/PageTitle'
import { UserRole } from '~/app/common/constants'
import { $Product, ProductType } from '~/app/common/types'
import productDefaults$ from '~/app/store/productDefaults'
import { useMappedDispatch, useMappedState } from '~/app/hooks/useReduxStore'
import { type ProductDefault } from '~/app/store/productDefaults/types'
import { Block } from '~/app/components/Block'
import { FieldSet } from '~/app/components/FieldSet'
import { selectProducts } from '~/app/store/products/selectors'
import { fetchAllProducts } from '~/app/store/products/actions'
import Button from '~/app/components/Button/Button'
import DeliveryForm from './DeliveryForm'
import { productTypeNamePlural } from '~/app/common/dataMaps/productTypeName'

interface Props {
  match: {
    params: {
      type: ProductType
    }
  }
}

export default function ProductDefaults(props: Props) {
  const {
    isSuperAdmin,
    productDefaultsState,
    productsOptionsByType,
    updateProductsDefaultState,
    onSave,
    type,
  } = useController(props)

  if (!isSuperAdmin || !productDefaultsState) {
    return null
  }

  return (
    <Page>
      <Title>Manage Product Defaults for {productTypeNamePlural[type]}</Title>
      {Object.entries(productDefaultsState).map(([mealsPerDelivery, productDefaults]) => (
        <Block key={mealsPerDelivery}>
          <FieldSet legend={`${mealsPerDelivery} meals per delivery`}>
            <DeliveryForm
              productDefaults={productDefaults}
              productOptions={productsOptionsByType}
              onChange={updateProductsDefaultState}
              mealsPerDelivery={Number(mealsPerDelivery)}
              type={type}
            />
          </FieldSet>
          <Button
            primary
            onClick={() => onSave(Number(mealsPerDelivery))}
            style={{ display: 'block', margin: '10px auto' }}
          >
            Save
          </Button>
        </Block>
      ))}
    </Page>
  )
}

function useController(props: Props) {
  const [productDefaultsState, updateProductsDefaultState] = useImmer<{
    [key: number]: ProductDefault[]
  } | null>(null)
  const type = props.match.params.type
  const normalizedType = type === ProductType.biteable ? ProductType.plate : type
  const isSuperAdmin = useHasUserRole([UserRole.superadmin])
  const dispatcher = useMappedDispatch({ fetchAllProducts })
  const { productDefaults, products } = useMappedState((state: Record<string, any>) => ({
    productDefaults: productDefaults$.getProductDefaults(state) as {
      [key in ProductType]: ProductDefault[]
    },
    products: selectProducts(state).allProducts as $Product[],
  }))
  const productsOptionsByType = products
    ?.filter((product) => product.type === normalizedType)
    .map((product) => ({
      label: product.name,
      value: product.id,
    }))

  const onSave = async (mealsPerDelivery: number) => {
    const data = productDefaultsState![mealsPerDelivery]

    const productsCount = data.reduce((acc, productDefault) => acc + productDefault.count, 0)

    if (productsCount !== mealsPerDelivery) {
      alert('The total count of products should be equal to the meals per delivery')
      return
    } else if (data.find((productDefault) => !productDefault.productId || !productDefault.count)) {
      alert('Please fill all the fields')
      return
    }

    await productDefaults$.call.editProductDefaults({ data })
  }

  useEffect(() => {
    dispatcher.fetchAllProducts(normalizedType)
    productDefaults$.call.fetchProductDefaults(type)
  }, [type])

  useEffect(() => {
    if (productDefaults) {
      const productDefaultsByType = groupBy(productDefaults[type], 'mealsPerDelivery')
      updateProductsDefaultState(productDefaultsByType)
    }
  }, [productDefaults])

  return {
    isSuperAdmin,
    productDefaultsState,
    productsOptionsByType,
    updateProductsDefaultState,
    onSave,
    type,
  }
}
