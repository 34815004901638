import Tooltip from '~/app/components/Tooltip'
import InputSelect from '~/app/components/InputTypes/Select'
import InputNumber from '~/app/components/InputTypes/Number'
import TrashIcon from '~/app/assets/svg/TrashIcon'
import Button from '~/app/components/Button/Button'
import { ProductDefault } from '~/app/store/productDefaults/types'
import type { Updater } from 'use-immer'
import { ProductType } from '~/app/common/types'
import { Wrapper, Row, DeleteButton } from './styled'

interface Props {
  productDefaults: ProductDefault[]
  productOptions: { label: string; value: number }[]
  onChange: Updater<{
    [key: number]: ProductDefault[]
  } | null>
  mealsPerDelivery: number
  type: ProductType
}

export default function DeliveryForm(props: Props) {
  return (
    <Wrapper>
      {props.productDefaults.map((productDefault, index) => (
        <div key={index}>
          <Row>
            <InputSelect
              options={props.productOptions}
              value={productDefault.productId}
              onChange={(value) =>
                props.onChange((draft) => {
                  draft![props.mealsPerDelivery][index].productId = Number(value)
                })
              }
              hasEmptyOption
              emptyLabel=" -- Select a product -- "
              defaultValue=""
              disabled={false}
            />
            <InputNumber
              // @ts-ignore
              value={productDefault.count}
              onChange={(value: number) =>
                props.onChange((draft) => {
                  draft![props.mealsPerDelivery][index].count = value
                })
              }
              minValue={1}
              maxValue={99}
            />
            <Tooltip text="Delete">
              <DeleteButton
                onClick={() =>
                  props.onChange((draft) => {
                    draft![props.mealsPerDelivery] = draft![props.mealsPerDelivery].filter(
                      (productDefaultEl) => productDefaultEl.productId !== productDefault.productId,
                    )
                  })
                }
              >
                <TrashIcon />
              </DeleteButton>
            </Tooltip>
          </Row>
        </div>
      ))}
      <Button
        secondary
        small
        onClick={() =>
          props.onChange((draft) => {
            draft![props.mealsPerDelivery][draft![props.mealsPerDelivery].length] = {
              count: 1,
              productId: 0,
              minMonths: 0,
              subscriptionType: props.type,
              mealsPerDelivery: props.mealsPerDelivery,
            }
          })
        }
        style={{ width: 'fit-content' }}
      >
        Add Product
      </Button>
    </Wrapper>
  )
}
