import { required } from '~/app/common/validators'
import InputTextarea from '~/app/components/InputTypes/Textarea'
import SmallText from '~/app/components/InputTypes/SmallText'
import DateInput from '~/app/components/InputTypes/Date'
import Switch from '~/app/components/InputTypes/Switch'
import ColorPicker from '~/app/components/InputTypes/ColorPicker'
import MultipleCheckboxes from '~/app/components/InputTypes/MultipleCheckboxes'
import anchorProductTypes from '~/app/enums/anchorProductTypes'
import { validAnchorProductTypesMap } from '~/app/common/types'

const formDefinition = [
  {
    label: 'Promo start date *',
    name: 'startDate',
    validators: [required],
    type: DateInput,
  },
  {
    label: 'Promo end date *',
    name: 'endDate',
    validators: [required],
    type: DateInput,
  },
  {
    label: 'Promo banner background *',
    name: 'backgroundColor',
    emptyLabel: 'Select a Color',
    hasEmptyOption: true,
    type: ColorPicker,
    validators: [required],
  },
  {
    label: 'Text *',
    name: 'title',
    validators: [required],
    type: InputTextarea,
  },
  {
    label: 'Text color',
    name: 'textColor',
    emptyLabel: 'Select a Color',
    type: ColorPicker,
    validators: [required],
  },
  {
    label: 'Description *',
    name: 'description',
    type: InputTextarea,
    validators: [required],
  },
  {
    label: 'Banner redirect url *',
    name: 'redirectURL',
    validators: [required],
  },
  {
    label: 'Coupon code',
    name: 'couponCode',
    type: SmallText,
  },
  {
    label: 'Auto apply coupon only on click',
    name: 'autoApplyCouponOnClick',
    value: false,
    type: Switch,
  },
  {
    label: 'For non authorized users only',
    name: 'isForNonAuthUsersOnly',
    value: false,
    type: Switch,
  },
  {
    label: 'Footer terms text',
    name: 'footerTermsText',
    type: InputTextarea,
  },
  {
    label: 'Targeting types',
    name: 'targetingTypes',
    options: anchorProductTypes
      .filter(
        (type) =>
          type.name !== validAnchorProductTypesMap.puff &&
          type.name !== validAnchorProductTypesMap.snack,
      )
      .map((type) => ({ id: type.name, name: type.name })),
    type: MultipleCheckboxes,
  },
]

export default formDefinition
