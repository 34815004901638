import { injectGlobal } from 'styled-components'

/* eslint-disable no-unused-expressions */
injectGlobal`
  html,
  body,
  #app {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
   html {
    box-sizing: border-box;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscaled;
    font-size: 62.5%;
  }


  body {
    font-family: 'Lato', sans-serif;
    color: #333;
    background: #f7f9fa;
    font-size: 1.6rem;
  }

  img {
    max-width: 100%;
  }

  button {
   cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 1em 0;
  }

  h1 {
    font-size: 40px;
    font-weight: 100;
  }

  p {
    font-weight: 300;
  }

  table {
    width: 100%;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker-wrapper input
  {
    width: 100%;
  }

  .react-datepicker-wrapper input {
    -webkit-appearance: none;
    background: none;
    border: solid 1px #d8dce3;
    border-radius: 4px;
    box-shadow: none;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    height: 50px;
    padding: 0 18px;

    &::placeholder {
      color: #939aa6;
    }
  }
`
