import moment from 'moment'
import LightBox from '~/app/components/LightBox'
import Tabs from '~/app/components/Tabs'
import ArrowUp from '~/app/assets/svg/ArrowUp'
import { getQuantityNotice } from '~/app/utils/getQuantityNotice'
import formatProductType from '~/app/utils/formatProductType'
import isProductABiteable from '~/app/utils/isProductABiteable'
import type { ValidProductType } from '~/app/common/types'
import { SUPPORTED_MISC_PRODUCTS_SKUS } from '~/app/common/constants'
import { validAddOnProductTypesMap, ProductType } from '~/app/common/types'
import pricingGroup$ from '~/app/store/pricingGroup'
import { PricingGroup } from '~/app/store/pricingGroup/types'
import { useMappedState } from '~/app/hooks/useReduxStore'
import SelectableProductsList from '../../../../SelectableProductsList'
import {
  Wrapper,
  Header,
  Title,
  Info,
  OrdersDate,
  OrderSummary,
  ProductCount,
  ProductTotal,
  Content,
  QuantityNotice,
  ContentWrapper,
} from './styled'

interface Discount {
  discountAmount: number
}

type Product = {
  id: number
  count: number
  type: string
  packetCount: number
  inStock: boolean
  shadow: string
  name: string
  top: string
}

interface Props {
  isOpen: boolean
  plan: {
    // @ts-expect-error: TODO: Fix type error
    mealsPerDay: null | number
    // @ts-expect-error: TODO: Fix type error
    mealsPerDelivery: number
    [key: string]: string
  }
  order: any
  addOnsQ: number
  closeHandler: () => void
  handleTabChange: () => void
  activeTab: ProductType
  products: Array<Product>
  addOnProducts: Array<Product>
  initialProductCount: number
  orderSummary: { subtotalCents: number; savings: Array<Discount> }
}

export default function EditedOrderLightBox(props: Props) {
  const self = useController(props)
  return (
    <LightBox isOpen={props.isOpen} closeHandler={props.closeHandler} width="1200px" height={830}>
      <Wrapper>
        <Header>
          <Title>Order Detail</Title>
          <OrdersDate>{self.deliveryDate.format('dddd, MMMM DD, YYYY')}</OrdersDate>
          <Info>This order can be modified until {self.modifyUntil}</Info>
          {(props.activeTab === props.order.type || props.activeTab === null) && (
            <OrderSummary>
              <ProductCount>
                {formatProductType(self.type)}: {self.selectedProductsCount}
              </ProductCount>
              {props.orderSummary && props.orderSummary.subtotalCents && (
                <>
                  <ProductTotal>
                    {formatProductType(self.type)} Subtotal: $
                    {props.orderSummary.subtotalCents / 100}
                  </ProductTotal>
                  <ProductTotal>
                    {formatProductType(self.type)} Discounts: $
                    {getDiscountTotal(props.orderSummary.savings) / 100}
                  </ProductTotal>
                  <ProductTotal>
                    {formatProductType(self.type)} Total: $
                    {(props.orderSummary.subtotalCents -
                      getDiscountTotal(props.orderSummary.savings)) /
                      100}
                  </ProductTotal>
                </>
              )}
            </OrderSummary>
          )}
          {props.activeTab !== props.order.type && props.activeTab !== null && (
            <OrderSummary>
              <ProductTotal>
                {formatProductType(props.activeTab)} add-ons:{' '}
                {getAddonTotal(props.activeTab, props.addOnProducts)}
              </ProductTotal>
            </OrderSummary>
          )}
        </Header>
        <Content>
          <Tabs
            // @ts-expect-error: TODO: Fix type error
            defaultTab={self.type}
            activeTab={props.activeTab || self.type}
            handleTabChange={props.handleTabChange}
            tabs={[
              {
                content: (
                  <ContentWrapper>
                    {self.productsLeftToPick.isInvalidCount && (
                      <QuantityNotice>
                        <ArrowUp />
                        Please {getQuantityNotice(self.productsLeftToPick.count, self.type)} to
                        complete this order
                      </QuantityNotice>
                    )}
                    <SelectableProductsList
                      products={self.products}
                      mealsLeftToPick={self.productsLeftToPick.count}
                      isFirst={props.order.isFirst}
                      type={self.type}
                      // @ts-expect-error: TODO: Fix type error
                      countMultiplier={1}
                      onlyQuantityEditor
                      disableClose={self.productsLeftToPick.isInvalidCount}
                    />
                  </ContentWrapper>
                ),
                name: self.type,
                title: formatProductType(self.type),
              },
              // @ts-expect-error: TODO: Fix type error
              ...self.addOnTabs.map((type) => {
                const addOnProducts = props.addOnProducts.filter((product) =>
                  type === ProductType.misc
                    ? SUPPORTED_MISC_PRODUCTS_SKUS.includes(product.sku)
                    : product.type === type,
                )
                const addOnsLeftToPick = self.calculateAddOnsLeftToPick(
                  self.addOnProducts[type],
                  addOnProducts,
                  props.order.chargedAt,
                  type,
                )
                return {
                  content: (
                    <ContentWrapper>
                      {addOnsLeftToPick !== 0 && (
                        <QuantityNotice>
                          <ArrowUp />
                          Please {getQuantityNotice(addOnsLeftToPick, type)} to complete this order
                        </QuantityNotice>
                      )}
                      <SelectableProductsList
                        isAddOn
                        products={addOnProducts}
                        mealsLeftToPick={addOnsLeftToPick}
                        isFirst={props.order.isFirst}
                        type={type}
                        // @ts-expect-error: TODO: Fix type error
                        countMultiplier={1}
                        onlyQuantityEditor
                      />
                    </ContentWrapper>
                  ),
                  name: type,
                  title: formatProductType(type),
                }
              }),
            ]}
          />
        </Content>
      </Wrapper>
    </LightBox>
  )
}

function getProductTotal(products: Product[]) {
  return products.reduce((sum: number, product: Product) => (sum += product.count), 0)
}

function getproductsLeftToPick(
  initialProductCount: number,
  selectedProductsCount: number,
  type: ValidProductType,
  chargedAt: Date,
  pricingGroup: PricingGroup,
) {
  const maxProductsCount = pricingGroup?.incrementalityConstraints[type].max
  const minProductsCount = pricingGroup?.incrementalityConstraints[type].min
  // If selectedProductsCount falls outside min/max product count range, show QuantityNotice
  if (selectedProductsCount > maxProductsCount) {
    return { isInvalidCount: true, count: selectedProductsCount - maxProductsCount }
  }
  if (selectedProductsCount < minProductsCount) {
    return { isInvalidCount: true, count: minProductsCount - selectedProductsCount }
  }
  if (chargedAt) {
    return {
      isInvalidCount: initialProductCount - selectedProductsCount !== 0,
      count: initialProductCount - selectedProductsCount,
    }
  }
  return { isInvalidCount: false, count: maxProductsCount - selectedProductsCount }
}

function getAddonTotal(selectedType: ProductType, addOnProducts: any) {
  const selectedAddOnProducts = addOnProducts.filter(
    (product: any) => product.type === selectedType,
  )
  return getProductTotal(selectedAddOnProducts)
}

function getDiscountTotal(discounts: Array<Discount>) {
  if (!discounts?.length) {
    return 0
  }
  return discounts.reduce(
    (discountTotal: number, discount: Discount) => (discountTotal += discount?.discountAmount),
    0,
  )
}

function useController(props: any) {
  const { pricingGroup } = useMappedState((state: Record<string, any>) => ({
    pricingGroup: pricingGroup$.getPricingGroup(state) as PricingGroup,
  }))
  const deliveryDate = props.order.deliveryDate
  const editableTo = moment(props.order.editableTo).add(
    process.env.EDITABLE_TO_EXTENSION_IN_HOURS || 0,
    'h',
  )
  const modifyUntil = editableTo.format('MMMM Do [at] ha')
  // orders are sent on the same shipment
  const canChangePlan = props.order.changeable.plan
  const type = props.order.type
  const products = props.products
    .filter((product: any) => {
      if (!product.published) return false

      if (type === ProductType.luncher) {
        return (
          product.type === ProductType.luncher ||
          (product.type === ProductType.plate && !isProductABiteable(product))
        )
      } else if (type === ProductType.biteable) {
        return product.type === ProductType.plate || isProductABiteable(product)
      }

      return product.type === type
    })
    .sort((product1: any, product2: any) => {
      // Display order: products in order > products with main orders' product type > other products
      if (product1.count > 0 && product2.count > 0) {
        if (product1.type !== type && product2.type === type) return 1
        if (product1.type === type && product2.type !== type) return -1
      }

      if (product1.count > 0 && product2.count <= 0) return -1
      if (product1.count <= 0 && product2.count > 0) return 1

      return product1.type === type && product2.type !== type ? -1 : 1
    })

  const selectedProductsCount = getProductTotal(products)
  const productsLeftToPick = getproductsLeftToPick(
    props.initialProductCount,
    selectedProductsCount,
    type,
    props.order.chargedAt,
    pricingGroup,
  )
  const addOnTabs = props.order.addOns.reduce(
    // @ts-expect-error: TODO: Fix type error
    (acc, { type, active, skipped }: any) => (active && !skipped ? [...acc, type] : acc),
    [],
  )

  const addOnProducts = props.order.addOns.reduce(
    // @ts-expect-error: TODO: Fix type error
    (acc, { type, products }: any) => ({ ...acc, [type]: products }),
    {},
  )

  function calculateAddOnsLeftToPick(
    addOnProducts: Product[],
    editedAddOnProducts: Product[],
    chargedAt: Date,
    type: ValidProductType,
  ) {
    const addOnProductsCount = addOnProducts && getProductTotal(addOnProducts)
    const editedAddOnProductsCount = getProductTotal(editedAddOnProducts)
    if (chargedAt) {
      return addOnProductsCount - editedAddOnProductsCount
    }
    const addOnType = type === validAddOnProductTypesMap.smoothie ? 'smoothieAddon' : type
    const maxProductsCount = pricingGroup?.incrementalityConstraints[addOnType].max
    if (editedAddOnProductsCount > maxProductsCount) {
      return maxProductsCount - editedAddOnProductsCount
    }

    return 0
  }

  return {
    addOnProducts,
    addOnTabs,
    calculateAddOnsLeftToPick,
    canChangePlan,
    deliveryDate,
    modifyUntil,
    products,
    productsLeftToPick,
    type,
    selectedProductsCount,
  }
}
