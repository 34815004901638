import { makeEffects } from '~/app/utils/storeManager'
import { fetchMagicLink, clearMagicLink } from './magicLink/effects'
import { fetchUserDetails, editUserDetails } from './details/effects'
import {
  fetchUserSubscriptions,
  pauseUserSubscription,
  cancelUserSubscription,
  postAddOnSubscription,
  postponeFirstOrder,
} from './subscriptions/effects'
import { fetchUserCredits, postUserCredits } from './credits/effects'
import { postUserCoupon, fetchUserCoupons } from './coupons/effects'
import { fetchUserProductCoupons } from './productCoupons/effects'
import {
  fetchUpcomingOrders,
  fetchOrderSummary,
  fetchOrdersSummaryByEditableTo,
  saveEditedOrder,
  skipOrder,
  shiftOrder,
  skipLinkedOrder,
  forceChargeOrder,
  forceCartonizeShip,
  markOrderShipped,
  createLabelsForShipments,
  sendTrackingNotifications,
  shiftAllOrders,
} from './deliverySchedule/effects'
import { fetchOrders, setFaultyOrder } from './pastOrders/effects'
import patchKid from './kids/effects'
import { fetchUserFeatureFlags } from './userFeatureFlags/effects'
import { fetchUserAuditLogs, clearUserAuditLogs } from './logs/effects'

const effects = makeEffects({
  cancelUserSubscription,
  clearMagicLink,
  editUserDetails,
  fetchMagicLink,
  fetchOrders,
  fetchUpcomingOrders,
  fetchOrderSummary,
  fetchOrdersSummaryByEditableTo,
  fetchUserCoupons,
  fetchUserCredits,
  fetchUserDetails,
  fetchUserFeatureFlags,
  fetchUserProductCoupons,
  fetchUserSubscriptions,
  forceChargeOrder,
  forceCartonizeShip,
  markOrderShipped,
  createLabelsForShipments,
  sendTrackingNotifications,
  patchKid,
  pauseUserSubscription,
  postAddOnSubscription,
  postUserCoupon,
  postUserCredits,
  postponeFirstOrder,
  saveEditedOrder,
  setFaultyOrder,
  shiftOrder,
  shiftAllOrders,
  skipLinkedOrder,
  skipOrder,
  fetchUserAuditLogs,
  clearUserAuditLogs,
})

export default effects
