import moment from 'moment'
import { Cell } from '~/app/components/Table'
import ProductTypeIcon from '~/app/components/Icons/ProductTypeIcon'
import FaultyOrderIcon from '~/app/assets/svg/FaultyOrderIcon'
import { AddOnType, ProductType } from '~/app/common/types'
import { OrderProductCountReturnType } from './helpers'
import { getChargeDetails } from './utils'
import { ProductsCount, Count, ProductsCounts, IconWrapper, Wrapper } from './styled'
import type { Order } from './types'
import { sumBy, groupBy, orderBy } from 'lodash'
import type { Props as WithFaultyOrderProps } from './withFaultyOrderController'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { DiscountType } from './types'

interface Props {
  index: number
  onClick: React.Dispatch<React.SetStateAction<number | null>>
  onSetAsFaulty: WithFaultyOrderProps['setFaultyOrder']
  order: Order
  productCount: OrderProductCountReturnType
}

export default function UserOrder(props: Props) {
  const self = useController(props)

  if (!self.productsByIdOrSku) {
    return null
  }

  return (
    <Wrapper
      onClick={() => props.onClick(props.order.id)}
      danger={props.order.deliveryIssues?.length}
      informative={props.order.type === 'manual'}
    >
      <Cell>#{props.order.id}</Cell>
      <Cell>{self.shipmentId}</Cell>
      <Cell>{self.orderDate}</Cell>
      <Cell>
        {props.order.type === 'manual'
          ? `Manual order - ${props.order.status}`
          : props.order.status}
      </Cell>
      <Cell>{self.couponCode}</Cell>

      <Cell>
        <ProductsCounts>
          {self.productTypeCounts.map((product) => (
            <ProductsCount key={product.type}>
              <Count>{product.count}</Count>
              <ProductTypeIcon type={product.type} />
            </ProductsCount>
          ))}
        </ProductsCounts>
      </Cell>
      <Cell>{props.order.charge && `$${self.charge.orderValueCentsWithDiscount}`}</Cell>
      {self.allowReporting && (
        <Cell>
          <IconWrapper
            isRed={Boolean(props.order.deliveryIssues?.length)}
            onClick={self.handleSetFaultyOrder}
          >
            <FaultyOrderIcon />
            {props.order.deliveryIssues?.length ? 'Report Another Issue' : 'Report Issue'}
          </IconWrapper>
        </Cell>
      )}
    </Wrapper>
  )
}

function useController(props: Props) {
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))
  const couponCode = getCouponCode(props)
  const charge = getChargeDetails(props.order, productsByIdOrSku)
  const productsByType = groupBy(props.order.products, 'type')
  const anchorProductCount = Object.entries(productsByType).map(([type, products]) => {
    return {
      type,
      count: sumBy(products, 'count'),
    } as { type: AddOnType | ProductType; count: number }
  })
  const addOnsCount = props.order.addOns.map((addOn) => {
    return { type: addOn.type, count: sumBy(addOn.products, 'count') }
  })
  const orderDate = moment(props.order.processedAt).format('DD MMM YYYY')
  const addOnTypeToCountMap = new Map<AddOnType, number>(
    props.order.addOns.map((addOn) => [addOn.type, sumBy(addOn.products, 'count')]),
  )
  const productTypeCounts = orderBy([...anchorProductCount, ...addOnsCount], 'count', 'desc')
  const handleSetFaultyOrder = (event: Event) => {
    event.stopPropagation()
    props.onSetAsFaulty(props.order)
  }
  const shipmentId = props.order.shipment ? `#${props.order.shipment.id}` : ''
  const allowReporting =
    props.order.status !== 'pending' &&
    moment(props.order.editableTo).isAfter(moment().subtract(3, 'months'))

  return {
    addOnTypeToCountMap,
    productTypeCounts,
    charge,
    couponCode,
    handleSetFaultyOrder,
    orderDate,
    productsByIdOrSku,
    shipmentId,
    allowReporting,
  }
}

/**
 * Finds the used coupon in the order.
 * When order has template level code, returns it, otherwise returns user level code.
 * @param props - Properties
 */
function getCouponCode({ order: { templateId, charge } }: Props) {
  const { receiptJson } = charge
  const findTemplateLevelCoupon = () =>
    receiptJson?.discounts.find(
      (discount) => discount.templateId === templateId && discount.type === DiscountType.coupon,
    )
  const findUserLevelCode = () =>
    receiptJson?.discounts.find(
      (discount) => !discount.templateId && discount.type === DiscountType.coupon,
    )
  return (findTemplateLevelCoupon() || findUserLevelCode())?.discountCode
}
