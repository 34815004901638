import magicLink from './magicLink/initialState'
import details from './details/initialState'
import coupons from './coupons/initialState'
import credits from './credits/initialState'
import productCoupons from './productCoupons/initialState'
import subscriptions from './subscriptions/initialState'
import deliverySchedule from './deliverySchedule/initialState'
import pastOrders from './pastOrders/initialState'
import userFeatureFlags from './userFeatureFlags/initialState'
import logs from './logs/initialState'

const initialState = {
  coupons,
  credits,
  deliverySchedule,
  details,
  magicLink,
  pastOrders,
  productCoupons,
  subscriptions,
  userFeatureFlags,
  logs,
}

export default initialState
