import { useState } from 'react'
import useForm from '~/app/hooks/useForm'
import Button from '~/app/components/Button/Button'
import Api from '~/app/common/api'
import { CenteredRow, ThreeFourth } from '~/app/components/FormGrid'
import formDefinition from './formDefinition'
import type { Order } from '~/app/common/api/types'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { ISSUES_OPTION_IDS_TO_SHOW_PRODUCTS_SELECTOR } from '../constants'
import {
  Products,
  Product,
  ButtonsRow,
  PlusButton,
  MinusButton,
  SubTitle,
  ImageUploadSection,
  ImagesContainer,
  ImageWrapper,
} from './styled'
import { Error } from '~/app/components/Input/styled'

interface Props {
  faultyOrderReasons: {
    name: string
    options: {
      label: string
      value: number
    }[]
  }[]
  issueIds: number[]
  resend: boolean
  isFaulty: boolean
  note: string
  photosProvided: boolean
  photos?: string[]
  giftProducts: any[]
  intercomLink?: string
  products: { sku: string; count: number }[]
  extraProduct: number | boolean
  onSubmit: (data: {
    issueIds: string[]
    note: string
    resend: string
    photosProvided: string
    extraProductIds: string
    products: { sku: string; count: number }[]
    intercomLink: string
    photos: string[]
  }) => void
  order?: Order
}

export default function FaultyOrderForm(props: Props) {
  const self = useController(props)
  const { formState, renderField, isValid, hasChanges } = self

  return (
    <div>
      <CenteredRow>
        <ThreeFourth>
          {renderField('primaryIssue', {
            disabled: props.isFaulty,
            optionsGroups: props.faultyOrderReasons,
            value: props.isFaulty ? props.issueIds[0] : formState.primaryIssue.value,
          })}
        </ThreeFourth>
      </CenteredRow>
      {(props.isFaulty
        ? ISSUES_OPTION_IDS_TO_SHOW_PRODUCTS_SELECTOR.includes(props.issueIds[0]) &&
          self.productIssues.length > 0
        : ISSUES_OPTION_IDS_TO_SHOW_PRODUCTS_SELECTOR.includes(
            Number(formState.primaryIssue.value),
          )) && (
        <CenteredRow>
          <ThreeFourth>
            <SubTitle>ISSUE RELATED PRODUCTS</SubTitle>
            <Products>
              {self.productIssues.map((product, index) => (
                <Product key={product.sku}>
                  <span>
                    {self.productIssues[index].count}
                    {props.isFaulty ? ' x ' : `/${props.order?.products[index].count} `}
                    {self.productsByIdOrSku?.[product.sku]?.name ?? product.sku}
                  </span>
                  {!props.isFaulty && (
                    <ButtonsRow>
                      {/* @ts-ignore */}
                      <MinusButton onClick={() => self.decreaseProductIssue(index)}>-</MinusButton>
                      <PlusButton onClick={() => self.increaseProductIssue(index)}>+</PlusButton>
                    </ButtonsRow>
                  )}
                </Product>
              ))}
            </Products>
          </ThreeFourth>
        </CenteredRow>
      )}
      {props.isFaulty && props.issueIds[1] && (
        <CenteredRow>
          <ThreeFourth>
            {renderField('secondaryIssue', {
              disabled: true,
              optionsGroups: props.faultyOrderReasons,
              value: props.issueIds[1],
            })}
          </ThreeFourth>
        </CenteredRow>
      )}
      {((props.isFaulty && props.intercomLink) || !props.isFaulty) && (
        <CenteredRow>
          <ThreeFourth>
            {renderField('intercomLink', {
              disabled: props.isFaulty,
              value: props.isFaulty ? props.intercomLink : formState.intercomLink.value,
            })}
          </ThreeFourth>
        </CenteredRow>
      )}
      <CenteredRow>
        <ThreeFourth>
          {renderField('note', {
            disabled: props.isFaulty,
            value: props.isFaulty ? props.note : formState.note.value,
          })}
        </ThreeFourth>
      </CenteredRow>
      <CenteredRow>
        {renderField('photosProvided', {
          disabled: props.isFaulty,
          value: props.isFaulty ? props.photosProvided : formState.photosProvided.value,
        })}
      </CenteredRow>
      {(formState.photosProvided.value || (props.isFaulty && props.photosProvided)) && (
        <ImageUploadSection>
          <CenteredRow>
            <ThreeFourth>
              {!props.isFaulty && self.photosUpload.uploadedPhotos.length > 0 && (
                <ImagesContainer>
                  {self.photosUpload.uploadedPhotos.map((src, index) => (
                    <ImageWrapper key={index} href={src} target="_blank" rel="noopener noreferrer">
                      <img src={src} alt="Order delivery issue" />
                    </ImageWrapper>
                  ))}
                </ImagesContainer>
              )}
              {props.isFaulty && (props.photos?.length ?? 0) > 0 && (
                <ImagesContainer>
                  {props.photos?.map((src, index) => (
                    <ImageWrapper key={index} href={src} target="_blank" rel="noopener noreferrer">
                      <img src={src} alt="Order delivery issue" />
                    </ImageWrapper>
                  ))}
                </ImagesContainer>
              )}
              {!props.isFaulty && (
                <Button
                  secondary
                  loading={self.photosUpload.loading}
                  disabled={self.photosUpload.uploadedPhotos.length >= self.photosUpload.MAX_PHOTOS}
                >
                  <label>
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={self.photosUpload.handleChange}
                    />
                    Add Image
                  </label>
                </Button>
              )}
              {self.photosUpload.uploadError && <Error>{self.photosUpload.uploadError}</Error>}
            </ThreeFourth>
          </CenteredRow>
        </ImageUploadSection>
      )}
      {(formState.resend.value || (props.isFaulty && props.extraProduct)) && (
        <CenteredRow>
          {renderField('extraProductIds', {
            disabled: props.isFaulty,
            options: props.giftProducts,
            value: props.isFaulty ? props.extraProduct : formState.extraProductIds.value,
          })}
        </CenteredRow>
      )}
      {!props.isFaulty && (
        <CenteredRow>
          <Button
            primary
            large
            disabled={!isValid}
            onClick={() => self.handleConfirm()}
            // @ts-ignore
            hasChanges={hasChanges}
          >
            Submit
          </Button>
        </CenteredRow>
      )}
    </div>
  )
}

function useController(props: Props) {
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))
  const { productIssues, setProductIssues, increaseProductIssue, decreaseProductIssue } =
    useProductIssuesController(props, productsByIdOrSku)
  // @ts-ignore
  const form = useForm({
    fieldDefinitions: formDefinition,
  })
  const { serialize, formState } = form
  const photosUpload = usePhotosUpload()

  function handleConfirm() {
    const formattedIssueProducts = productIssues.filter((product) => product.count > 0)

    if (formState.photosProvided.value && photosUpload.uploadedPhotos.length === 0) {
      return alert('Please upload at least one photo.')
    }

    serialize(() => {
      props.onSubmit({
        extraProductIds: formState.extraProductIds.value,
        issueIds: [formState.primaryIssue.value, formState.secondaryIssue.value],
        note: formState.note.value,
        photosProvided: formState.photosProvided.value,
        resend: formState.resend.value,
        products: formattedIssueProducts,
        intercomLink: formState.intercomLink.value,
        photos: photosUpload.uploadedPhotos,
      })
    })
  }

  return {
    ...form,
    handleConfirm,
    productIssues,
    setProductIssues,
    decreaseProductIssue,
    increaseProductIssue,
    productsByIdOrSku,
    photosUpload,
  }
}

function useProductIssuesController(props: Props, productsByIdOrSku: any) {
  const [productIssues, setProductIssues] = useState(
    props.isFaulty
      ? props.products ?? []
      : props.order?.products.map((product) => ({
          sku: productsByIdOrSku[product.id]?.sku ?? product.name,
          count: 0,
        })) ?? [],
  )

  const decreaseProductIssue = (index: number) => {
    const updatedProductIssues = [...productIssues]
    if (updatedProductIssues[index].count === 0) {
      return
    }

    const product = updatedProductIssues[index]
    updatedProductIssues[index] = { ...product, count: product.count - 1 }
    setProductIssues(updatedProductIssues)
  }

  const increaseProductIssue = (index: number) => {
    const updatedProductIssues = [...productIssues]
    if (updatedProductIssues[index].count === props.order?.products[index].count) {
      return
    }

    const product = updatedProductIssues[index]
    updatedProductIssues[index] = { ...product, count: product.count + 1 }
    setProductIssues(updatedProductIssues)
  }

  return {
    productIssues,
    setProductIssues,
    decreaseProductIssue,
    increaseProductIssue,
  }
}

function usePhotosUpload() {
  const [loading, setLoading] = useState(false)
  const [uploadError, setUploadError] = useState('')
  const [uploadedPhotos, setUploadedPhotos] = useState<string[]>([])
  const MAX_PHOTOS = 5

  const handleChange = async (e: any) => {
    setLoading(true)
    setUploadError('')

    try {
      if (uploadedPhotos.length >= MAX_PHOTOS) {
        return setUploadError(`Maximum number of photos is ${MAX_PHOTOS}.`)
      }

      const [file] = e.target.files
      const { type: fileType, name: filename, size: fileSize } = file
      const KB_MULTIPLIER = 1024
      const MAX_FILE_SIZE_DEFAULT_KB = 2000 * KB_MULTIPLIER // 2MB
      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg']

      if (!allowedImageTypes.includes(fileType)) {
        return setUploadError(
          `Only images of the following formats are allowed: ${allowedImageTypes.join(', ')}`,
        )
      }

      if (fileSize > MAX_FILE_SIZE_DEFAULT_KB) {
        return setUploadError(
          `Maximum file size for images is ${MAX_FILE_SIZE_DEFAULT_KB / 1024}KB`,
        )
      }

      const { finalUrl, url } = await Api.uploadOrderDeliveryIssuePhoto({
        filename,
      })
      await fetch(url, {
        body: file,
        headers: { 'Content-Type': fileType },
        method: 'PUT',
      })
      setUploadedPhotos((prev) => [...prev, finalUrl])
    } catch (error: any) {
      setUploadError(`Failed to upload photo: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  return { loading, uploadError, uploadedPhotos, handleChange, MAX_PHOTOS }
}
