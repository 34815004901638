// TODO: Add static types https://littlespoon.atlassian.net/browse/LS-1361
// ! Don't remove booster types, it causes bugs! We still need them to make some things work.
import PropTypes from 'prop-types'

export enum validAnchorProductTypesMap {
  blend = 'blend',
  plate = 'plate',
  biteable = 'biteable',
  smoothie = 'smoothie',
  luncher = 'luncher',
  snack = 'snack',
  puff = 'puff',
  cereal = 'cereal',
}

export enum validAddOnProductTypesMap {
  booster = 'booster',
  smoothie = 'smoothie',
  misc = 'misc',
  puff = 'puff',
}

export const validProductTypesMap = {
  ...validAnchorProductTypesMap,
  ...validAddOnProductTypesMap,
} as const

export const validProductPluralTypesMap = {
  blends: 'blends',
  boosters: 'boosters',
  plates: 'plates',
  smoothies: 'smoothies',
  lunchers: 'lunchers',
  snacks: 'snacks',
  puffs: 'puffs',
  cereal: 'cereal',
} as const

export const VALID_ANCHOR_PRODUCT_TYPES = Object.values(validAnchorProductTypesMap)

export const VALID_ADD_ON_PRODUCT_TYPES = Object.values(validAddOnProductTypesMap)

export const VALID_PRODUCT_TYPES = Object.values(validProductTypesMap)

type Values<O extends Record<any, any>> = O[keyof O]

export type ValidProductType = (typeof validProductTypesMap)[keyof typeof validProductTypesMap]

export enum ProductType {
  misc = 'misc',
  blend = 'blend',
  booster = 'booster',
  luncher = 'luncher',
  plate = 'plate',
  biteable = 'biteable',
  sauce = 'sauce',
  smoothie = 'smoothie',
  snack = 'snack',
  puff = 'puff',
  cereal = 'cereal',
}

export type AddOnType = Extract<
  ProductType,
  ProductType.booster | ProductType.sauce | ProductType.smoothie | ProductType.misc
>

// Behaves like an enum, but also ensures it's a subset of ProductType enum
export const AddOnType = {
  [ProductType.booster]: ProductType.booster,
  [ProductType.sauce]: ProductType.sauce,
  [ProductType.smoothie]: ProductType.smoothie,
  [ProductType.misc]: ProductType.misc,
} as const

export const statusMap = Object.freeze({
  error: 'error',
  loaded: 'loaded',
  loading: 'loading',
  pending: 'pending',
})

export type StatusType = Values<typeof statusMap>

export enum DataStatus {
  pending = 'pending',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export type TagType = {
  [key: string]: number | string
  name: string
}

export type FormFactorType = {
  description: string
  lengthInches: number
  widthInches: number
  heightInches: number
  weightPounds: number
  sequence?: number
}

export type PromoBanner = {
  id: null | number
  backgroundColor: string
  couponCode?: null | string
  coupon: null | { code: string }
  description: string
  endDate: string
  isForNonAuthUsersOnly: boolean
  footerTermsText?: string
  autoApplyCouponOnClick?: boolean
  redirectURL: string
  startDate: string
  textColor: string
  title: string
  targetingTypes?: string[]
}

export type ReasonType = { id: number; value: string }
export type ReasonTypes = ReasonType[]

export type ShippingBoxType = {
  name: string
  heightInches: number
  weightTarePounds: number
  lengthInches: number
  priceCents: number
  weightMaxPounds: number
  widthInches: number
}

export interface ShippingBoxWithIdType extends ShippingBoxType {
  id: number
}

export const planStatusMap = Object.freeze({
  active: 'active',
  cancelled: 'cancelled',
  incomplete: 'incomplete',
  paused: 'paused',
})

export const PLAN_STATUS_TYPE = Object.values(planStatusMap)

type StatusKeys = keyof typeof planStatusMap

export type PlanStatusType = (typeof planStatusMap)[StatusKeys]

export type $WarehousesType = { id: number; name: string; shortName: string }[]

export const KidType = {
  age: PropTypes.number,
  allergy: PropTypes.string,
  birthdate: PropTypes.string,
  eatSolid: PropTypes.bool,
  eatingStyle: PropTypes.string,
  eatsMeat: PropTypes.bool,
  foodOrigin: PropTypes.string,
  gender: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  phase: PropTypes.string,
}

// TODO: LS-1361 depricate these types in favor of static types
export const shippingInfoType = {
  carrier: PropTypes.string,
  code: PropTypes.string,
  packDay: PropTypes.string,
  region: PropTypes.string,
  service: PropTypes.string,
  transitDays: PropTypes.number,
  warehouseId: PropTypes.number,
}

// TODO: LS-1361 depricate these types in favor of static types
export const AddressType = {
  address: PropTypes.string,
  apt: PropTypes.string,
  city: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  note: PropTypes.string,
  phone: PropTypes.string,
  shippingInfo: PropTypes.shape(shippingInfoType),
  state: PropTypes.string,
  type: PropTypes.string,
  zip: PropTypes.string,
}

export const NewsletterType = {
  app: PropTypes.bool,
  digest: PropTypes.bool,
  personal: PropTypes.bool,
}

// TODO: populate the any to be more specific
export const UserType = {
  addresses: PropTypes.arrayOf(PropTypes.shape(AddressType)),
  carrier: PropTypes.string,
  createdAt: PropTypes.string,
  credits: PropTypes.number,
  deletedAt: PropTypes.string,
  dueDate: PropTypes.string,
  email: PropTypes.string,
  feedback: PropTypes.any,
  firstName: PropTypes.string,
  id: PropTypes.number,
  kids: PropTypes.arrayOf(PropTypes.shape(KidType)),
  lastName: PropTypes.string,
  newsletter: PropTypes.shape(NewsletterType),
  note: PropTypes.string,
  onboardingZip: PropTypes.string,
  roles: PropTypes.any,
  service: PropTypes.string,
  shippingInfo: PropTypes.shape(shippingInfoType),
  tags: PropTypes.any,
  transitDays: PropTypes.number,
  type: PropTypes.string,
  updatedAt: PropTypes.string,
  warehouseId: PropTypes.number,
}

export const PlanType = {
  amountPerMeal: PropTypes.number,
  id: PropTypes.number,
  mealsPerDay: PropTypes.number,
  shippingCost: PropTypes.number,
}

export const UpcomingType = {
  editableTo: PropTypes.string,
  plan: PropTypes.shape(PlanType),
  shiftedBy: PropTypes.number,
  skip: PropTypes.bool,
  skippedAt: PropTypes.string,
}

export const SubscriptionType = {
  active: PropTypes.bool,
  deactivatedAt: PropTypes.string,
  id: PropTypes.number.isRequired,
  kidId: PropTypes.number.isRequired,
  upcoming: PropTypes.shape(UpcomingType),
}

export const AddOnDataType = {
  active: PropTypes.bool.isRequired,
  changeable: PropTypes.shape({ skip: PropTypes.bool }),
  planId: PropTypes.number,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      id: PropTypes.number,
      price: PropTypes.number,
    }),
  ),
  skipped: PropTypes.bool,
  templateId: PropTypes.number,
}

export const BlendDataType = {
  active: PropTypes.bool.isRequired,
  blueprintId: PropTypes.number.isRequired,
  changeable: PropTypes.shape({ plan: PropTypes.bool, skip: PropTypes.bool }),
  planId: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      id: PropTypes.number,
      price: PropTypes.number,
    }),
  ),
  skipped: PropTypes.bool.isRequired,
  templateId: PropTypes.number.isRequired,
}

export const IngredientType = {
  createdAt: PropTypes.string.isRequired,
  deletedAt: PropTypes.string,
  description: PropTypes.string,
  iconUrl: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
}

export const SymbolType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export const BlueprintProductType = {
  addOn: PropTypes.bool,
  blend: PropTypes.bool,
  // @ts-expect-error TODO: Fix error
  createdAt: PropTypes.string.required,
  description: PropTypes.string,
  freeGift: PropTypes.bool,
  id: PropTypes.number.isRequired,
  inStock: PropTypes.bool,
  ingredients: PropTypes.arrayOf(PropTypes.shape(IngredientType)),
  ingredientsText: PropTypes.string,
  name: PropTypes.string.isRequired,
  nutritionFull: PropTypes.string,
  nutritionHighlight: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      val: PropTypes.shape({
        highlightName: PropTypes.string,
        highlightValue: PropTypes.string,
      }),
    }),
  ),
  packetCount: PropTypes.number,
  // For individually priced products, is a number. For others, is false.
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  productReplacements: PropTypes.arrayOf(PropTypes.any), // TODO shape of replacements
  recommendedAge: PropTypes.string,
  seoKeywords: PropTypes.string,
  seoTitle: PropTypes.string,
  sequence: PropTypes.number,
  shadow: PropTypes.string,
  simple: PropTypes.string,
  sku: PropTypes.string,
  spoon: PropTypes.string,
  stage: PropTypes.number,
  symbols: PropTypes.arrayOf(PropTypes.shape(SymbolType)),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  thumbnail: PropTypes.string,
  top: PropTypes.string,
  type: PropTypes.string,
  upc: PropTypes.string,
  updatedAt: PropTypes.string,
}

export enum ProductImageType {
  nutritionFull = 'nutritionFull',
  shadow = 'shadow',
  simple = 'simple',
  sleeve = 'sleeve',
  spoon = 'spoon',
  thumbnail = 'thumbnail',
  top = 'top',
  sliderImage2 = 'sliderImage2',
  sliderImage3 = 'sliderImage3',
  sliderImage4 = 'sliderImage4',
  sliderImage5 = 'sliderImage5',
}

export type $Product = {
  addOn: boolean
  blend: boolean
  createdAt: string
  description: string
  dietaryTags: TagType[]
  freeGift: boolean
  id: number
  inStock: boolean
  ingredients: (typeof IngredientType)[]
  ingredientsText: string
  name: string
  nutritionFull: string
  nutritionFullAltText: string | null
  nutritionHighlight: {
    productId: number
  }
  packetCount: null | number
  // For individually priced products, is a number. For others, is false.
  price: number | false
  productReplacements: any[] // TODO shape of replacements https://littlespoon.atlassian.net/browse/LS-1361
  recommendedAge: string
  seoKeywords: string
  seoTitle: string
  sequence: number
  shadow: string
  shadowAltText: string | null
  simple: string
  simpleAltText: string | null
  sku: string
  sleeve: string
  sleeveAltText: string | null
  spoon: string
  spoonAltText: string | null
  stage: number
  symbols: (typeof SymbolType)[]
  tags: TagType[]
  thumbnail: string
  thumbnailAltText: string | null
  top: string
  topAltText: string | null
  type: string
  upc: string
  updatedAt: string
  published: boolean
  isNew: boolean | null
  heavyMetals?: {
    arsenicMgPerKg?: number
    cadmiumMgPerKg?: number
    mercuryMgPerKg?: number
    leadMgPerKg?: number
  }
}

export type OrderProductType = $Product & {
  count: number
  price: number
  subType: string | null
  productDietaryTags: {
    dietaryTagId: number
    name: string
  }[]
}

export const DashboardHeaderType = {
  aggColumn: PropTypes.string,
  detailsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      rowOne: PropTypes.string,
      rowTwo: PropTypes.string,
    }),
  ),
  prevColumn: PropTypes.string,
}

export interface ShippingInfo {
  carrier: string
  service: string
  region: string
  transitDays: number
  warehouseId: number
  code: string
  packDay: string
}

export interface Address {
  firstName: string
  lastName: string
  address: string
  apt?: string
  city: string
  state: string
  zip: string
  type: string
  shippingInfo: ShippingInfo
}

export interface Link {
  name: string
  path: string
}
