export const BLOCK_ROUTE_CHANGE_FOR_UNSAVED_WORK = 'history-block-target'

export enum NodeEnv {
  development = 'development',
  local = 'local',
  production = 'production',
  staging = 'staging',
}

export enum UserRole {
  superadmin = 'superadmin',
  admin = 'admin',
  'user-manager' = 'user-manager',
}

export const states = {
  alabama: 'AL',
  alaska: 'AK',
  arizona: 'AZ',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  'district of columbia': 'DC',
  florida: 'FL',
  georgia: 'GA',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'puerto rico': 'PR',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY',
} as const

// See https://moz.com/learn/seo/alt-text:
// > Keep it (relatively) short. It's advisable to keep alt text at around 125 characters or less. It was previously
// > advised that popular screen readers cut alt text off after around 125 characters. While no longer a hard and
// > fast rule, it is still a good guide for content creators and SEOs.
export const IMAGE_ALT_TEXT_MAXIMUM_LENGTH = 125

export const ALT_TEXT_SUFFIX = 'AltText'

export const SUPPORTED_MISC_PRODUCTS_SKUS = [
  'BIB',
  'INCLUDE-COOLER',
  'LUNCHMULTI',
  'LUNCHGREEN',
  'LUNCHPURPLE',
  'LSSLAPPACK',
  'LSPOGS',
  'ALLPIN',
  'ONESIE-POTATO',
  'ONESIE-TOAST',
]

export const NEW = 'new' as const
