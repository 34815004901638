import Api from '~/app/common/api'
import loader$ from '~/app/store/loader'
import alert$ from '~/app/store/alert'

/**
 * Sends request to API to get upcoming orders for a given user
 */
export function fetchUpcomingOrders(store: any, self$: any) {
  return async (userId: number): Promise<void> => {
    try {
      const response = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(response)
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to get order summary for a given user
 */
export function fetchOrderSummary(store: any, self$: any) {
  return async (payload: { userId: number; order: any }): Promise<void> => {
    const { userId, order } = payload
    const mainProducts =
      order.products && order.products.map(({ id, count }: any) => ({ quantity: count, id }))
    const cart = [...mainProducts]

    try {
      const response = await Api.fetchOrderSummary(userId, cart, order.type)
      self$.call.setOrderSummary(response)
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
  }
}

/**
 * Sends request to API to get orders summary for a given user by editableTo date
 */
export function fetchOrdersSummaryByEditableTo(store: any, self$: any) {
  return async (payload: {
    userId: number
    editableTo: string
    isSecondUpcomingWeek?: boolean
  }): Promise<void> => {
    const { userId, editableTo, isSecondUpcomingWeek } = payload

    try {
      const response = await Api.fetchOrdersSummaryByEditableTo(userId, editableTo)
      if (isSecondUpcomingWeek) {
        self$.call.setSecondUpcomingWeekOrdersSummary(response)
      } else {
        self$.call.setUpcomingWeekOrdersSummary(response)
      }
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
  }
}

/**
 * Sends request to API to modify an order
 */
export function saveEditedOrder(store: any, self$: any) {
  return async (payload: {
    orderId: number
    // editedOrder has an id on it, but this is not the orderId. It is the templateId.
    editedOrder: any
    userId: number
    shouldUpdateAllFutureOrders: boolean
  }): Promise<void> => {
    loader$.call.show()
    const { editedOrder, userId, orderId, shouldUpdateAllFutureOrders } = payload
    const addOnProducts =
      editedOrder.addOnProducts &&
      editedOrder.addOnProducts.map(({ id, count }: any) => ({ quantity: count, id }))
    const mainProducts =
      editedOrder.products &&
      editedOrder.products.map(({ id, count }: any) => ({ quantity: count, id }))
    const formattedOrder = {
      cart: [...mainProducts, ...addOnProducts],
      shouldUpdateAllFutureOrders,
    }
    try {
      await Api.editOrderV3(userId, orderId, formattedOrder)

      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)
      alert$.call.setNotification('Order has been saved successfully!')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to shift an order
 */
export function shiftOrder(store: any, self$: any) {
  return async (payload: {
    orderId: number
    userId: number
    editableTo: string
    shiftBy: number
  }): Promise<void> => {
    loader$.call.show()

    const { orderId, userId, editableTo, shiftBy } = payload
    try {
      await Api.shiftOrder(userId, orderId, editableTo, shiftBy)
      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)
      alert$.call.setNotification('Order has been shifted')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to shift all week orders
 */
export function shiftAllOrders(store: any, self$: any) {
  return async (payload: {
    userId: number
    editableTo: string
    shiftBy: number
  }): Promise<void> => {
    loader$.call.show()

    const { userId, editableTo, shiftBy } = payload
    try {
      await Api.shiftAllOrders(userId, editableTo, shiftBy)
      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)
      alert$.call.setNotification('Orders have been shifted')
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to skip an addon order
 */
export function skipLinkedOrder(store: any, self$: any) {
  return async (payload: {
    templateId: number
    userId: number
    editableTo: string
    skip: boolean
  }): Promise<void> => {
    loader$.call.show()

    const { templateId, userId, editableTo, skip } = payload
    try {
      await Api.skipLinkedOrder(userId, templateId, editableTo, skip)
      const upcomingOrders = await Api.fetchUpcomingOrders(payload.userId)
      // @ts-ignore
      self$.call.setUpcomingOrders(upcomingOrders)
      alert$.call.setNotification(`Add on order has been ${skip ? '' : 'un'}skipped`)
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to skip an order
 */
export function skipOrder(store: any, self$: any) {
  return async (payload: {
    id: number
    userId: number
    editableTo: string
    skip: boolean
    force: boolean
  }): Promise<void> => {
    loader$.call.show()

    const { id, userId, editableTo, skip, force = false } = payload
    try {
      if (force) {
        await Api.forceUnskip(id, editableTo)
      } else {
        await Api.skipOrder(userId, id, editableTo, skip)
      }
      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)
      alert$.call.setNotification(`Order has been ${skip ? '' : 'un'}skipped`)
    } catch (error) {
      alert$.call.setNotification(String(error))
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to force charge orders in non-production environments
 */
export function forceChargeOrder(store: any, self$: any) {
  return async (payload: {
    userId: number
    editableTo: string
    deliveryEstimate: string
  }): Promise<void> => {
    loader$.call.show()
    const { userId, editableTo, deliveryEstimate } = payload
    try {
      const data = { editableTo }
      const { ordersCount } = await Api.forceCharge(userId, data)
      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)

      if (ordersCount === 1) {
        alert$.call.setNotification(
          `${ordersCount} order with delivery date ${deliveryEstimate} has been charged.`,
        )
      } else {
        alert$.call.setNotification(
          `${ordersCount} orders with delivery date ${deliveryEstimate} have been charged.`,
        )
      }
    } catch (error) {
      alert$.call.setNotification(
        `There was an error charging orders with delivery date ${deliveryEstimate}. ${error}`,
      )
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to force cartonize and ship orders in non-production environments
 */
export function forceCartonizeShip(store: any, self$: any) {
  return async (payload: {
    userId: number
    editableTo: string
    deliveryEstimate: string
  }): Promise<void> => {
    loader$.call.show()
    const { userId, editableTo, deliveryEstimate } = payload
    try {
      const data = { editableTo }
      const { shipmentsCount } = await Api.forceCartonizeShip(userId, data)
      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)

      if (shipmentsCount === 1) {
        alert$.call.setNotification(
          `${shipmentsCount} shipment with delivery date ${deliveryEstimate} has been cartonized and sent to ShipStation.`,
        )
      } else {
        alert$.call.setNotification(
          `${shipmentsCount} shipments with delivery date ${deliveryEstimate} have been cartonized and sent to ShipStation.`,
        )
      }
    } catch (error) {
      alert$.call.setNotification(
        `There was an error cartonizing + shipping orders with delivery date ${deliveryEstimate}. ${error}`,
      )
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to test mark shipped an order on non-production environments
 */
export function markOrderShipped(store: any, self$: any) {
  return async (payload: { userId: number; orderId: number }): Promise<void> => {
    loader$.call.show()
    const { userId, orderId } = payload
    try {
      await Api.markShipped(userId, orderId)
      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)
      const pastOrders = await Api.fetchOrders(userId)
      self$.call.setPastOrders(pastOrders)
      alert$.call.setNotification(`Order #${orderId} has been marked shipped in shipstation`)
    } catch (error) {
      alert$.call.setNotification(
        `There was an error marking orderId ${orderId} shipped in ShipStation. ${error}`,
      )
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to create shipping labels on non-production environments
 */
export function createLabelsForShipments(store: any, self$: any) {
  return async (payload: {
    userId: number
    editableTo: string
    deliveryEstimate: string
  }): Promise<void> => {
    loader$.call.show()
    const { userId, editableTo, deliveryEstimate } = payload
    try {
      const data = { editableTo }

      const { labelsCount } = await Api.createLabelsForShipments(userId, data)

      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)

      if (labelsCount === 1) {
        alert$.call.setNotification(
          `${labelsCount} shipping label has been created for delivery date ${deliveryEstimate}.`,
        )
      } else {
        alert$.call.setNotification(
          `${labelsCount} shipping labels have been created for delivery date ${deliveryEstimate}`,
        )
      }
    } catch (error) {
      alert$.call.setNotification(
        `There was an error creating labels for delivery date ${deliveryEstimate}. ${error}`,
      )
    }
    loader$.call.hide()
  }
}

/**
 * Sends request to API to send tracking notifications on non-production environments
 */
export function sendTrackingNotifications(store: any, self$: any) {
  return async (payload: {
    userId: number
    editableTo: string
    deliveryEstimate: string
  }): Promise<void> => {
    loader$.call.show()
    const { userId, editableTo, deliveryEstimate } = payload
    try {
      const data = { editableTo }

      await Api.sendTrackingNotifications(userId, data)

      const upcomingOrders = await Api.fetchUpcomingOrders(userId)
      self$.call.setUpcomingOrders(upcomingOrders)

      alert$.call.setNotification(
        `Tracking notifications have been sent for shipments with delivery date ${deliveryEstimate}`,
      )
    } catch (error) {
      alert$.call.setNotification(
        `There was an error sending notifications for delivery date ${deliveryEstimate}. ${error}`,
      )
    }
    loader$.call.hide()
  }
}
