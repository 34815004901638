import { Fragment } from 'react'
import moment from 'moment'
import { capitalize, sumBy } from 'lodash'
import LightBox from '~/app/components/LightBox'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import pluralize from '~/app/utils/pluralize'
import { useMappedState } from '~/app/hooks/useReduxStore'
import ProductsDetails from './ProductsDetails'
import { Wrapper, Header, Title, Info, OrdersDate, Content, Row, Cell, Code } from './styled'
import DeliveryIssues from './DeliveryIssues'
import { getProductText, OrderProductCountReturnType, getTotalProductCount } from '../helpers'
import { getChargeDetails } from '../utils'
import type { Order, OrderProduct, OrderProductGroup, QuoteProduct } from '../types'

interface Props {
  order: Order
  closeHandler: () => void
  productCount: OrderProductCountReturnType
}

export default function OrderDetails(props: Props) {
  const self = useController(props)

  if (!self.productsByIdOrSku) {
    return null
  }

  return (
    <LightBox isOpen closeHandler={props.closeHandler} width="914px" height={830}>
      <Wrapper>
        <Header>
          <Title>Order Detail</Title>
          <OrdersDate>{self.orderDate.format('dddd, MMMM DD, YYYY')}</OrdersDate>
          <Info>
            Order #{props.order.id} was placed on {self.orderDate.format('dddd, MMMM DD, YYYY')} and
            is currently {props.order.status}
          </Info>
        </Header>

        <Content>
          <Row>
            <Cell strong>Product</Cell>
            <Cell strong>Total</Cell>
          </Row>
          <Row noBorder={!self.isHybridOrder}>
            <Cell strong>
              {props.order.type === 'manual' ? 'Total Products' : getProductText(props.order.type)}{' '}
              {getTotalProductCount(props.order.products)}
            </Cell>
            <Cell strong>${convertCentsToDollars(self.subtotalOrderPriceCents)}</Cell>
          </Row>

          {self.isHybridOrder ? (
            self.productsGroups.map((productGroup) => (
              <>
                <Row noBorder>
                  <Cell strong>
                    {pluralize(
                      capitalize(productGroup.type),
                      getTotalProductCount(productGroup.products),
                    )}{' '}
                    {getTotalProductCount(productGroup.products)}
                  </Cell>
                  <Cell strong>${convertCentsToDollars(productGroup.totalPriceCents)}</Cell>
                </Row>
                <ProductsDetails products={productGroup.products} />
              </>
            ))
          ) : (
            <ProductsDetails products={props.order.products} />
          )}

          {props.order.addOns.map(
            (addOn) =>
              !addOn.skipped && (
                <Fragment key={addOn.type}>
                  <Row noBorder>
                    <Cell strong>{getProductText(addOn.type)}</Cell>
                    <Cell strong>${self.charge.addOnTypeToTotalValueMap.get(addOn.type)}</Cell>
                  </Row>
                  <ProductsDetails products={addOn.products} />
                </Fragment>
              ),
          )}

          {props.order.type !== 'manual' && (
            <Row noBorder>
              <Cell>Payment Method</Cell>
              <Cell strong>Credit Card</Cell>
            </Row>
          )}

          {self.charge.shippingCents != null && (
            <Row>
              <Cell noBorder>Shipping</Cell>
              <Cell strong>
                {self.charge.shippingCents ? `$${self.charge.shippingCents}` : 'Free'}
              </Cell>
            </Row>
          )}

          {self.charge.orderDiscountValueCents && (
            <Row noBorder={Boolean(self.charge.orderProductTierDiscount)}>
              <Cell>Discounts / Credits Applied</Cell>
              <Cell strong>
                -$
                {self.charge.orderDiscountValueCents}
              </Cell>
            </Row>
          )}

          {self.charge.orderProductTierDiscount && (
            <Row>
              <Cell>Quantity Discount</Cell>
              <Cell strong>
                -$
                {self.charge.orderProductTierDiscount}
              </Cell>
            </Row>
          )}

          <Row>
            <Cell>Today's Total</Cell>
            <Cell strong>${self.charge.orderValueCentsWithDiscount}</Cell>
          </Row>

          {(props.order.deliveryIssues?.length ?? 0) > 0 && (
            /* @ts-ignore */
            <DeliveryIssues deliveryIssues={props.order.deliveryIssues} />
          )}

          {props.order.charge.receiptJson && (
            <Row block left>
              <details>
                <summary>Receipt</summary>
                <Code>{JSON.stringify(props.order.charge.receiptJson, null, 2)}</Code>
              </details>
            </Row>
          )}
        </Content>
      </Wrapper>
    </LightBox>
  )
}

function useController(props: Props) {
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))
  const orderDate = moment(props.order.processedAt)
  const charge = getChargeDetails(props.order, productsByIdOrSku)
  const receiptProducts =
    props.order.charge.receiptJson?.orders.find((chargeOrder) => chargeOrder.id === props.order.id)
      ?.itemizedList || []
  const productsGroups = getProductsGroups(props.order.products, receiptProducts)
  const isHybridOrder = productsGroups.length > 1
  const subtotalOrderPriceCents = sumBy(productsGroups, 'totalPriceCents')

  const hasOtherOrdersInCharge = (props.order.charge.receiptJson?.orders.length || 0) > 1

  return {
    charge,
    hasOtherOrdersInCharge,
    orderDate,
    productsByIdOrSku,
    isHybridOrder,
    productsGroups,
    subtotalOrderPriceCents,
  }
}

/**
 * Returns products groups with the total price of all products in cents
 * Each group represents one product type and all products of that type
 * Used for hybrid orders history
 */
function getProductsGroups(products: OrderProduct[], quoteProducts: QuoteProduct[]) {
  const groups: OrderProductGroup[] = []

  products.forEach((product) => {
    const group = groups.find((productGroup) => productGroup.type === product.type)
    const totalPriceCents =
      quoteProducts.find((quoteProduct) => quoteProduct.id === product.id)?.totalPriceCents || 0
    if (group) {
      group.products.push(product)
      group.totalPriceCents += totalPriceCents
    } else {
      groups.push({ type: product.type, products: [product], totalPriceCents })
    }
  })

  return groups
}
